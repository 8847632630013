import styles from './CardExemplo.module.css'

export default function CardExemplo(props){
  return(
    <div className={styles.exempleBox}>
      <h1>{props.exemplo}</h1>
      <div className={styles.images}>
        <img src={props.img1} alt="Exemplo 1" />
        <img src={props.img2} alt="Exemplo 2"/>
      </div>
    </div>
  )
}