import './App.css';
import testa1 from './assets/testa1.jpeg'
import testa2 from './assets/testa2.jpeg'
import queixo1 from './assets/queixo1.jpeg'
import queixo2 from './assets/queixo2.jpeg'
import orelha1 from './assets/orelha1.jpeg'
import orelha2 from './assets/orelha2.jpeg'
import boca1 from './assets/boca1.jpeg'
import boca2 from './assets/boca2.jpeg'
import CameraPhoto from './components/CameraPhoto';
import CameraStream from './components/CameraStream';
import styles from './Main.module.css';
import letmein from './assets/letmein.png'
import Instrucoes from './components/Instrucoes';
import CardExemplo from './components/CardExemplo';
import { useEffect, useState, Fragment } from 'react';
import SideBar from './components/SideBar';
import IdleTimer from './components/IdleTimer';

function App() {
  const [IAResult, setIAResult] = useState('')

  function handleReturn(string){
    setIAResult(string)
  }

  const [isActive, setIsActive] = useState(false)
  const [terms, setTerms] = useState(false)


  function clickHandler(){
    setIsActive(false)
  }

  function openHandler(){
    setIsActive(true)
  }

  const [isTimeout, setIsTimeout] = useState(false);
  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 180, //expire after 10 seconds
      onTimeout: () => {
        alert('Tempo de inatividade expirou !')
        window.location.reload()
        setIsTimeout(true);
      },
      onExpired: () => {
        
        setIsTimeout(true);
      }
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', () => {
      clickHandler()
    });
  }, [])


  

  return (
    <Fragment>
      {terms ?
        <SideBar click={isActive} open={openHandler}>
          <div className={styles.container}>
            <div className={styles.main}>
              <CameraStream handler={handleReturn} />
              <CameraPhoto result={IAResult} />
            </div>
            <h1>EXEMPLOS DE GESTOS</h1>
            <div className={styles.exemplos}>
              <CardExemplo exemplo="TESTA" img1={testa1} img2={testa2} />
              <CardExemplo exemplo="QUEIXO" img1={queixo1} img2={queixo2} />
              <CardExemplo exemplo="BOCA" img1={boca1} img2={boca2} />
              <CardExemplo exemplo="ORELHA" img1={orelha1} img2={orelha2} />
            </div>
          </div>
        </SideBar> :
        <div className={styles.terms}>
          <div className={styles.popUp}>
            <h1>Termo de uso e condições</h1>
            <p>Todas as imagens, vídeos ou conteúdo utilizadas neste domínio atendem aos requisitos da LGPD. Nenhuma das informações serão utilizadas ou publicadas sem consentimento do(a) usuário(a). A privacidade das mesmas estão sob absoluto sigilo da empresa.</p>
            <p>Para continuar, é necessário pressionar em "Aceito" abaixo.</p>
            <div className={styles.btnBox}>
              <button onClick={() => {window.location.reload()}} className={styles.deneid}>Recuso</button>
              <button onClick={() => {setTerms(true)}} className={styles.agree}>Aceito</button>
            </div>
          </div>
        </div>      
      }
    </Fragment>
  );
}

export default App;
