import camera from '../assets/camera.svg'
import styles from './CameraPhoto.module.css'

export default function CameraPhoto(props){
  var reformat = 'data:image/jpeg;base64,' + props.result

  return(
    <div className={styles.wrap}>
      <h1>Inteligência Artificial</h1>
      <div className={styles.container}>
        {props.result ? <img className={styles.result} src={reformat} alt="Resultado"/> : <img className={styles.cameraImage} src={camera} alt="foto"/>}
      </div>
    </div>
  )
}