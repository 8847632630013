import {useCallback, useRef, useState} from 'react';
import Webcam from 'react-webcam';
import styles from './CameraStream.module.css';

export default function CameraStream(props){
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };

  const [btnFlag, setBtnFlag] = useState(false)

  function sendImage(file, flag){
    fetch("https://ia.letmein.com.br/panic/panic64", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://ia.letmein.com.br/panic/panic64",
      },
      body: JSON.stringify({"file": file})
    }).then((response) => response.json())
    .then((data) => {
      console.log("Success:", data);
      setImgSrc(data.file)
      props.handler(data.file)
      if(flag){
        capture(true)
      }else{
        alert("Captura Finalizada")
        window.location.reload();
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
  }

  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);

  const capture = useCallback((flag) => {
        const imageSrc = webcamRef.current.getScreenshot();
        console.log(flag)
        setImgSrc(imageSrc)
        const format = imageSrc.slice(23, imageSrc.length - 1)
        console.log(flag)
        sendImage(format, flag)        
        
  }, [webcamRef, setImgSrc, sendImage]);


  
  return(
    <div className={styles.cameraBox}>
      <h1>Vídeo</h1>
      <div className={styles.container}>
        <Webcam
          audio={false}
          height={300}
          minScreenshotHeight={480}
          minScreenshotWidth={640}
          width={300}
          ref={webcamRef}
          videoConstraints={videoConstraints}
          screenshotFormat="image/jpeg"
        />
      </div>
      {!btnFlag ? 
        <div className={styles.input}>
          <button onClick={() => {
              setBtnFlag(true)
              capture(true)
          }}>INICIAR CAPTURA</button>
        </div>  :
        <div className={styles.input}>
          <button style={{backgroundColor: "#c00", color: "#fff"}} onClick={() => {
              setBtnFlag(false)
              capture(false)
          }}>PAUSAR CAPTURA</button>
        </div>
    }
    </div>
  )
}