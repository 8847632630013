import Instrucoes from './Instrucoes'
import styles from './SideBar.module.css'
import lmi from '../assets/lmi.svg'
import menu from '../assets/menu.svg'
import { useEffect, useState } from 'react'
import useWindowDimensions from './getWidth'


export default function SideBar(props){

  const { height, width } = useWindowDimensions();

 

  return(
    <div className={styles.sideBarWraper}>
      {width > 1175 || props.click ? 
        <div className={styles.sidebar}>
          <img src={lmi} />
          <Instrucoes />
        </div> :
        <button className={styles.sidebarMenu}>
          <img src={menu} alt="Menu"  onClick={() => {
          props.open()
        }}/>
        </button> 
    }
      <div className={styles.infoContainer}>
        {props.children}
      </div>
    </div>
  )
}