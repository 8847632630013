import styles from './Instrucoes.module.css'
export default function Instrucoes(){
  return(
    <div className={styles.instrucoes} >
      <h1>
        Instruções
      </h1>
      <h2>1. Veja os exemplos de gestos listados</h2>
      <h2>2. Enquadre o rosto na câmera</h2>
      <h2>3. Execute um gesto por vez</h2>
      <h2>4. Repita esse processo para cada gesto</h2>
      <h2>5. Inteligência artificial</h2>
    </div>
  )
}